import React from "react";
import { RichText } from "prismic-reactjs";
import "./style.scss";

export default function Features({ data }) {
  return (
    <section className="iam_features">
      <div className="container">
        <div className="iam_features_header">
          <div className="image_wrapper">
            <img src={data.primary.logo.url} alt="" />
          </div>
          <RichText render={data.primary.description1} />
        </div>
        <div className="iam_features_wrapper">
          {data.items.map((content, index) => {
            return (
              <div key={index} className="iam_features_card">
                <h5>{index + 1}</h5>
                <RichText render={content.feature_title} />
                <RichText render={content.feature_description} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
