import React from "react";
import { RichText } from "prismic-reactjs";
import "./style.scss";

export default function Benefits({ data }) {
  return (
    <section className="home_benefits">
      <div className="container">
        <div className="home_benefits-description">
          <div>
            <RichText render={data.primary.description1} />
          </div>
        </div>
        <div className="home_benefits-title">
          <div className="dot benefits-dot-one"></div>
          <RichText render={data.primary.title1} />
          <div className="dot benefits-dot-two"></div>
        </div>
        <div className="home_benefits_content">
          {data.items.map((content, index) => {
            return (
              <div className="home_benefits_block" key={index}>
                <div>
                  <img src={content.icon.url} alt="" />
                  <RichText render={content.title1} />
                  <RichText render={content.description1} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
