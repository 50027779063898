import React from "react";
import Layout from "../../components/layout";
import SecondaryFooter from "../../components/layout/secondaryFooter";
import Hero from "../../components/products/iam/hero";
import Features from "../../components/products/iam/features";
import Benefits from "../../components/products/iam/benefits";
import { graphql } from "gatsby";

export default function iamForApplications(props) {
  if (!props.data) return null;
  const data = props.data.allPrismicProductsIam.edges[0].node.dataRaw;
  return (
    <Layout>
      <Hero data={data} />
      <Features data={data.body[0]} />
      <Benefits data={data.body[1]} />
      <SecondaryFooter />
    </Layout>
  );
}

export const products_iam = graphql`
  query products_iam {
    allPrismicProductsIam {
      edges {
        node {
          dataRaw
        }
      }
    }
  }
`;
