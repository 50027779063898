import React from "react";
import { RichText } from "prismic-reactjs";
import "./style.scss";

export default function hero({ data }) {
  return (
    <section
      className="home_hero"
      style={{
        backgroundImage: `url(${data.banner_bg_image.url})`,
      }}
    >
      <div className="container">
        <RichText render={data.title} />
        <RichText render={data.subtitle} />
      </div>
    </section>
  );
}
